export interface QuestionSettingsJson {
  longitudinal: boolean;
  overview: boolean;
}

export class QuestionSettings {
  constructor(public longitudinal: boolean, public overview: boolean) {}

  static fromJson(json: QuestionSettingsJson): QuestionSettings {
    return new QuestionSettings(json.longitudinal, json.overview);
  }
}
