import moment, { Moment } from 'moment';
import { SectionQuestion, SectionQuestionJson } from './section-question.model';

export interface SurveyEntryItemJson {
  id: number;
  question: SectionQuestionJson;
  value: any;
  created_at: string;
  updated_at: string;
}

export class SurveyEntryItem {
  constructor(
    public id: number,
    public question: SectionQuestion,
    public value: any,
    public createdAt: Moment,
    public updatedAt: Moment
  ) {}

  static fromJson(json: SurveyEntryItemJson): SurveyEntryItem {
    let parsedValue: any;

    try {
      parsedValue = JSON.parse(json.value);
    } catch (error) {
      parsedValue = json.value;
    }

    return new SurveyEntryItem(
      json.id,
      json.question ? SectionQuestion.fromJson(json.question) : null,
      parsedValue,
      json.created_at ? moment(json.created_at) : null,
      json.updated_at ? moment(json.updated_at) : null
    );
  }
}
